.page_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  .header-container {
    width: 100%;
    height:105px;
    border-bottom: 1px solid #acacac;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background:white;
    @media(max-width: $breakpoint-md) {
      padding:0 15px;
      height:auto;
      position:fixed;
      z-index:100;
      top:0;
    }
    img {
      margin-left: 48px;
      cursor: pointer;
    }
  }
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    @media screen and (max-width: $breakpoint-md) {
      height: 70px;
    }
    margin: auto;
    padding: 0 40px;
  }
  .logo-container{
    width:250px;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    @media(max-width:$breakpoint-md){
      justify-content:flex-start;
    }
  }
  .container {
    width: calc( 100% - 250px );
    height:100%;
  }
  .main-wrapper {
    margin: auto;
    // max-width: 1440px;
    position: absolute;
    left: 250px;
    top: 105px;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    .page-content-wrapper {
      width: 100%;
      // max-width: 1190px;
      margin: auto;
      padding: 40px;
      @media screen and (max-width: $breakpoint-md) {
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .page_wrapper {
    .main-wrapper {
      position:unset;
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      //left: 0;
      //padding: 0 10px;
      //top:90px;
      //bottom: unset;
    }
    .main-wrapper-lowered{
      top:0 !important;
    }
  }
}
