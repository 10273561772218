.reset-page{
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	padding:20px;
	position: relative;
	.close-button{
		position: absolute;
		top:0;
		right:0;
		width:100%;
		display:flex;
		justify-content:flex-end;
		align-items:flex-end;
		padding:20px;

	}
	.send-email-container{
		display:flex;
		flex-direction: column;
		width:500px;
		align-items:flex-start;
		justify-content:center;
		@media(max-width: 510px){
			width:100%;
		}
		.reset-header{
			color: #333033;
			font-family: 'Rubik', sans-serif;
			font-size: 36px;
			font-weight: 600;
			margin-bottom:15px;
		}
		.reset-text{
			color: #333033;
			font-family: 'Montserrat', sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 24px; /* 171.429% */
		}
		.button{
			border:none;
			outline:none;
			border-radius:50px;
			padding:15px 30px;
			margin:20px 0;
			cursor:pointer;
			background-color: #98D9C2;
			color: #333033;
			text-align: center;
			font-family: 'Montserrat', sans-serif;
			font-size: 14px;
			font-weight: 700;
		}
	}
}
