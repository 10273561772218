.messages-queue-page-wrapper {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	.messages-queue-header{
		width:100%;
		display:flex;
		justify-content:space-between;
		align-items:center;
		&.active{
			justify-content: center;
		}
		.blank{
			width:28px;
			height:16px;
		}
		.filter-buttons{
			display:flex;
			align-items:center;
			justify-content:center;
			.filter-button{
				border-radius: 100px;
				border: 1px solid #EDEDED;
				padding:14px 20px;
				color: #252525;
				cursor:pointer;
				font-family: 'Montserrat', sans-serif;
				font-size: 13px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin:0 13px;
				&.active {
					border-radius: 100px;
					border: 1px solid #EF8533;
					background: #EDEDED;
				}
			}
		}
	}
	.header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;

		button {
			width: 146px;
			height: 47px;
			flex-shrink: 0;
			border-radius: 50px;
			text-align: center;
			leading-trim: both;
			text-edge: cap;
			font-size: 14px;
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-right: 40px;
			border: none;
		}

		p {
			color: #333033;
			leading-trim: both;
			text-edge: cap;
			font-size: 14px;
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}

	.table-box-wrapper {
		margin-top: 82px;
		width: 100%;
		max-height: 100%;
		overflow-y: auto;
		.no-messages{
			width:100%;
			height:300px;
			display:flex;
			justify-content:center;
			align-items:center;
			span{
				color: #ABABAB;
				font-family: 'Montserrat', sans-serif;
				font-size: 19px;
				font-weight: 700;
			}
		}
		.messages-list {
			width: 100%;

			.message-item {
				width: 100%;
				margin:10px 0;
				.table-item {
					width: 100%;
					text-align: left;
					overflow:hidden;
					display: block;
					color: #252525;
					font-family: 'Montserrat', sans-serif;
					font-size: 14px;
					font-weight: 700;
				}
				.table-message{
					max-height:14px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-right:1px;
					&:hover{
						max-height:none;
						white-space: normal;
					}
				}
			}
		}

		.grid-container {
			padding-bottom: 30px;

			.table-h {
				width: 100%;
				text-align: left;
				display: block;
				color: #555;
				font-family: 'Montserrat', sans-serif;
				font-size: 12px;
				font-weight: 700;
			}
		}

		table {
			width: 100%;
			border-collapse: collapse;

			.main-row {
				border: none;
				line-height: 1000px;
			}

			tr {
				line-height: 30px;
				border-bottom: 1px solid #dbdbdb;

				th {
					color: #939393;
					leading-trim: both;
					text-edge: cap;
					font-size: 13px;
					font-family: 'Montserrat', sans-serif;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					text-align: start;
				}

				td {
					color: #333033;
					leading-trim: both;
					text-edge: cap;
					font-size: 14px;
					font-family: 'Montserrat', sans-serif;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					padding: 20px 0;
					text-align: start;
				}
			}

			.user-picture {
				width: 60px;
				height: 60px;
				border-radius: 60px;
				background: #ffda10;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				text-align: center;
				leading-trim: both;
				text-edge: cap;
				font-size: 28px;
				font-family: 'Montserrat', sans-serif;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				margin-right: 35px;
			}

			.wrapper {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
			}

			.sended-messages-wrapper {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}
	}

	.pagination {
		width:100%;
		display: flex;
		justify-content:flex-end;
		padding: 20px 0;
		color: #939393;
		font-size: 18px;
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		button {
			border: none;
			background-color: transparent;
			color: #939393;
			cursor:pointer;
			margin:0 10px;
			font-size: 16px;
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
}

.contact-component-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

@media screen and (max-width: $breakpoint-md) {
	.messages-queue-page-wrapper {
		padding: 0;

		.header {
			justify-content: center;
			flex-direction: column;

			button {
				margin-bottom: 40px;
			}

			p {
			}
		}

		.table-box-wrapper {
			margin-top: 80px;
			width: 100%;
			max-height: 600px;
			overflow-y: auto;

			table {
				.main-row {
				}

				tr {
					.name {
					}

					th {
					}

					td {
						padding-right: 20px;
					}
				}
			}
		}
	}
}
.dots{
	border:none;
	outline:none;
	background:transparent;
	display:flex;
	cursor:pointer;
	padding:0;
	margin:0;
	box-sizing: border-box;
	justify-content:center;
	align-items:center;
	user-select:none;
	color: #252525;
	font-family: 'Montserrat', sans-serif;
	font-size: 19px;
	width:100%;
	height:100%;
	font-weight: 700;
	letter-spacing:4px;
}
