.home-package{
  color: #252525;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media(max-width:$breakpoint-md){
    margin-top:0;
  }
  .manage-package{
    margin-left:30px;
    color: #252525;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    &:hover{
      color:#939393;
    }
  }
}
.home-title {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 30px 0;
}
.table-names{
  width:100%;
  .table-name-text{
    font-size:12px;
    font-family:'Montserrat', sans-serif;
    font-weight:700;
    color:#555;
  }
  .add-new-device{
    color: #252525;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor:pointer;
    margin-top:20px;
    line-height: normal;
    text-decoration-line: underline;
  }
  //@media(max-width:$breakpoint-md){
  //  display:none;
  //}
}

.device-list {
  width: 100%;
  .device {
    height:100px;
    width: 100%;
    border-bottom:1px solid #DBDBDB;
    padding-bottom:10px;
    margin-bottom:10px;
    .grid-item{
      display:flex;
      justify-content:flex-start;
      align-items:center;
      @media(max-width:$breakpoint-md){
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
      }
      .device-name{
        color: #252525;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .device-statistics{
        display:flex;
        flex-direction: column;
        height:100%;
        justify-content:center;
        .device-text{
          color: #252525;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin:5px 0;
          min-width:150px;
          display:flex;
          justify-content:space-between;
          align-items:center;
        }
      }
      .device-statistics-numbers{
        display:flex;
        flex-direction:column;
        height:100%;
        justify-content:center;
        .device-numbers{
          color: #252525;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin:5px 0;
          display:flex;
          justify-content:space-between;
          align-items:center;
        }
      }
      .increase-limit{
        color: #2A8C69;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        cursor:pointer;
      }
      .device-delete{
        padding:20px 25px;
        display:flex;
        justify-content:center;
        align-items:center;
        border-radius:10px;
        border:1px solid #ACACAC;
        outline:none;
        background:transparent;
        cursor:pointer;
        color: #EA0A0A;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:hover{
          color: #FFB3B3;
        }
      }
      .device-delete-dots{
        font-family: 'Montserrat', sans-serif;
        font-size: 25px;
        font-weight:700;
        margin-left:10px;
        color:#252525;
        cursor:pointer;
      }
    }
    .inactive{
      font-size:16px;
      margin:5px;
      font-family:'Montserrat', sans-serif;
      font-weight:500;
      color:#DF2935;
    }
    .active{
      font-size:16px;
      font-family:'Montserrat', sans-serif;
      font-weight:500;
      color:#6BA292;
      margin:5px;
    }
    .slot-title-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      width: 100%;
      .title {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .elements-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 70px 0;
    }
  }
  .mobile-device{
    width:100%;
    height:auto;
    padding:30px;
    margin:20px 0;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.15);
    border-radius:10px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    .device-header{
      width:100%;
      display:flex;
      justify-content:space-between;
      align-items:center;
      .device-name{
        color: #252525;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right:20px;
      }
      .header-more-actions{
        position:relative;
        .device-delete{
          position:absolute;
          top:20px;
          right:20px;
          z-index:100;
          padding:20px 25px;
          display:flex;
          justify-content:center;
          align-items:center;
          border-radius:10px;
          border:1px solid #ACACAC;
          background:#fff;
          outline:none;
          cursor:pointer;
          color: #EA0A0A;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .device-activation{
      width:100%;
      display:flex;
      justify-content:space-between;
      align-items:center;
      margin:20px 0;
    }
    .inactive{
      font-size:16px;
      font-family:'Montserrat', sans-serif;
      font-weight:500;
      color:#DF2935;
    }
    .active{
      font-size:16px;
      font-family:'Montserrat', sans-serif;
      font-weight:500;
      color:#6BA292;
    }
    .device-statistics{
      width:100%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:flex-start;
      padding:25px 0;
      border-top:1px solid #DBDBDB;
      border-bottom:1px solid #DBDBDB;
      .device-text{
        color: #252525;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin:10px 0;
        width:160px;
        display:flex;
        justify-content: space-between;
        align-items:center;
      }
    }
  }
}

.info-box {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #acacac;
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  .title {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .content {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.add-device-button {
  width: 146px;
  height: 47px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #ea0a8e;
  border: none;
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.create-new-slot-btn {
  width: 146px;
  height: 47px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #ea0a8e;
  border: none;
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 48px;
  margin-bottom: 30px;
}
