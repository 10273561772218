.btn {
  transition: 300ms;
  border: 1px solid black;
  cursor: pointer;
  background: rgb(234, 10, 142);
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(234, 10, 142, 0.25);
}
body::-webkit-scrollbar {
  background-color: white;
}
.btn:hover {
  background: #b62c7d;
}
.underline-text{
  color: #252525;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
  margin: 0 5px;
  cursor: pointer;
  &:hover{
    color:#939393;
  }
}
.loader {
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-color: #98D9C2 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-white {
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-color: white transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader{
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-color: #6BA292 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.active-account-container{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  height:auto;
  max-width:750px;
  .active-account{
    width:100%;
    height:100%;
    border-radius:5px;
    padding:10px;
    border:1px solid #DE6E4B;
    background-color:rgba(222, 110, 75, 0.10);
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-around;
    align-items: center;
    .activate-text{
      color: #DE6E4B;
      width:90%;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      margin-left:15px;
      font-weight: 400;
      line-height: normal;
      @media(max-width:400px){
        width:75%;
      }
      .bold-text{
        color: #DE6E4B;
        font-family: 'Montserrat', sans-serif;
        cursor:pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-decoration-line: underline;
        &:hover{
          color:#A73C1B;
        }
      }
    }
  }
}
.activate-popup{
  height: 100%;
  width: 500px;
  @media screen and (max-width: $breakpoint-md) {
    width: 100vw;
  }
  border-radius:10px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .close-button{
    width:100%;
    display:flex;
    justify-content:flex-end;
    align-items: center;
    padding:20px;
  }
  .popup-title{
    color: #252525;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .pin-container{
    width:250px;
    height:85px;
    background-color:#98D9C2;
    border-radius:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:5px 15px;
    margin-top:30px;
    .pin{
      color: #252525;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 46px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .pin-info{
    color: #555;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:5px;
  }
  .not-delivered{
    color: #252525;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin:20px 0 50px 0;
    .bold-text{
      color: #252525;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      cursor:pointer;
      text-decoration-line: underline;
      &:hover{
        color:#939393;
      }
    }
  }
}
.logout-container{
  height:220px;
  width:350px;
  border-radius:10px;
  background: #FFF;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  padding:60px 30px 40px 30px;
  @media(max-width:$breakpoint-md){
    width:100%;
    height:100%;
    border-radius:0;
    box-shadow:none;
    justify-content:center;
    align-items:center;
  }
  .logout-title{
    color: #252525;
    width:100%;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media(max-width:$breakpoint-md){
      font-size:35px;
      margin-bottom:20px;
    }
  }
  .logout-buttons{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    @media(max-width:$breakpoint-md){
      flex-direction:column;
      justify-content:center;
    }
    .back-to-app{
      height:auto;
      display:flex;
      justify-content:center;
      align-content:center;
      text-align: center;
      color: #252525;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      text-decoration-line: underline;
      cursor:pointer;
      @media(max-width:$breakpoint-md){
        margin-bottom:20px;
      }
    }
  }
}
.pin-popup {
  height: 100%;
  width: 500px;
  @media screen and (max-width: $breakpoint-md) {
    width: 100vw;
  }
  border-radius:10px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .close-button{
    width:100%;
    display:flex;
    justify-content:flex-end;
    align-items: center;
    padding:20px;
  }
  .popup-title{
    color: #252525;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .popup-subtitle{
    color: #252525;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .pin-container{
    width:auto;
    height:auto;
    background-color:#98D9C2;
    border-radius:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:5px 15px;
    margin-top:30px;
    .pin{
      color: #252525;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 46px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .pin-info{
    color: #555;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    margin-top:5px;
    font-weight: 400;
    line-height: normal;
  }
  .line{
    width:70%;
    height:1px;
    background-color:#DBDBDB;
    margin-top:20px;
    margin-bottom:40px;
  }
  .qr-title{
    color: #252525;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
  .qr-container{
    display:flex;
    width:70%;
    margin-top:30px;
    margin-bottom:50px;
    justify-content:space-between;
    align-items:flex-start;
    .qr-info{
      color: #252525;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      width: 60%;
    }
  }
}

.pop-up-button {
  width: 92px;
  height: 47px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #ea0a8e;
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  margin-bottom: 20px;
  margin-top: 30px;
}

.set-device-list-popup {
  width: 600px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    button {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 50px;
      padding: 10px 20px;
      border: none;
    }
  }
}
.captcha-container{
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  margin-top:20px;
}
.captcha-toleft-container{
  display: flex;
  justify-content:flex-start;
  align-items:center;
  width:100%;
  margin:10px 0 50px 0;
}
.captcha-none{
  display:none;
}
.add-contacts-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .title {
    color: #000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 40px;
  }
  .input-wrapper {
    margin-bottom: 20px;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .add-contant-button {
      border: none;
      padding: 10px 20px;
      margin-top: 20px;
      border-radius: 10px;
    }
    .close-contant-button {
      border: none;
      outline:none;
      color: #333033;
      background-color: transparent;
      box-shadow: none;
      font-family: 'Montserrat', sans-serif !important;
      cursor:pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
      &:hover{
        color:#939393;
      }
    }
  }
}


@media screen and (min-width: $breakpoint-md) {
  .all-btn {
    display: none;
  }
}

.all-btn div {
  float: left;
  margin-right: 10%;
}

.all-btn div:last-child {
  margin-right: 0;
}

.menu-btn-2 {
  height: 18px;
  width: 20px;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items: center;
  span{
    background:black;
    width: 100%;
    height: 2px;
    border-radius:20px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotateY(180deg);
    z-index:100;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
}

.menu-btn-2 span::before,
.menu-btn-2 span::after {
  background: black;
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  border-radius:20px;

  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotateY(180deg);

  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.menu-btn-2 span::before {
  margin-top: -6px;
}

.menu-btn-2 span::after {
  margin-top: 6px;
}

.menu-btn-2.active span {
  background: transparent;
}

.menu-btn-2.active span::before {
  margin-top: 0;
  background:white;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-btn-2.active span::after {
  margin-top: 0;
  background:white;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-btn-2.active {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);

  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.assign-tarrif-to-device-list {
  li {
    padding: 13px 0;
    border-bottom: 1px solid #000;

    button {
      margin-left: 30px;
      padding: 5px 10px;
      border-radius: 10px;
      border: none;
    }
  }
}

.fixed-position {
  // position: fixed;
  overflow: hidden;
}

.back-to-main-page-icon,
.back-to-main-page-icon-black {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  @media(max-width:700px){
    height:23px;
    width:23px;
    top:40px;
    right:30px;
  }
  @media(max-width:600px){
    right:30px;
  }
}
.back-to-main-page-icon:hover,
.back-to-main-page-icon-black:hover {
  opacity: 0.5;
}
.back-to-main-page-icon:before,
.back-to-main-page-icon:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 4px;
  border-radius:100px;
  background-color: #ffffff;
  @media(max-width:700px){
    height:23px;
    width:3px;
  }
}
.back-to-main-page-icon-black:before,
.back-to-main-page-icon-black:after {
  position: absolute;
  left: 15px;
  content: ' ';
  border-radius:100px;
  height: 33px;
  width: 4px;
  background-color: #333033;
  @media(max-width:700px){
    height:23px;
    width:3px;
  }
}
.back-to-main-page-icon:before,
.back-to-main-page-icon-black:before {
  transform: rotate(45deg);
}
.back-to-main-page-icon:after,
.back-to-main-page-icon-black:after {
  transform: rotate(-45deg);
}
.global-btn{
  border-radius: 100px;
  border:none;
  outline:none;
  background: #98D9C2;
  cursor: pointer;
  padding:13px 25px;
  margin:5px;
  color: #252525;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display:flex;
  justify-content:center;
  align-items:center;
  &:hover{
    background: #6BA292;
    color:#fff;
  }
  &:active{
    background: #98D9C2;
    color: #252525;
  }
  &.disabled{
    background: #b4b4b4;
    opacity:0.3;
    pointer-events: none;
  }
  &.full-width{
    width:100%;
  }
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor:pointer;
  }
}
.red-global-btn{
  background: #EA0A0A;
  color:#fff;
  &:hover{
    background: #EE6262;
  }
  &:active{
    background: #EE6262;
    color:#fff;
  }
}
.no-margin{
  margin:0;
}
.calendary-popup-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .calendary-btn {
    margin-top: 30px;
    width: 146px;
    height: 47px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #ea0a8e;
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    @media screen and (min-width: $breakpoint-md) {
      display: none;
    }
  }
}

.checkbox-label {
  position: relative;
  height: 25px;
  width: 25px;
  border: 1px solid #555555;
  border-radius: 6px;
  &:hover {
    border-color: #000;
  }
  .checkbox {
    transform: scale(1.923);
    opacity: 0;
  }
  .checked_div {
    display: none;
    position: absolute;
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px;
    background-color: #333033;
    border-radius: 3px;
  }
  &.smaller{
    width:20px;
    height:20px;
  }
}
