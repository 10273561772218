.header-elements-container{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-content:center;
  width:100%;
  .header-titles-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .date {
      color: #333033;
      leading-trim: both;
      text-edge: cap;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .title {
      color: #333033;
      font-size: 24px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    .header-titles-wrapper {
      display: none;
    }
  }
}
.mobile-activate{
  display:none;
  @media(max-width: $breakpoint-md) {
    display:flex;
    flex-direction:column;
    width:100%;
    justify-content:center;
    align-items:center;
    padding:0 10px;
    margin-top:80px;
    margin-bottom:30px;
    .header-titles-wrapper{
      color: #333033;
      font-size: 24px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width:100%;
      text-align:left;
      margin-top:20px;
      padding-left:10px;
    }
  }
}
.pc-activate{
  display:flex;
  flex-direction:row;
  flex-grow:1;
  justify-content:center;
  align-items:center;
  margin-left:20px;
  @media(max-width: $breakpoint-md) {
    display:none;
  }
}
