.messages-wrapper {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  @media(max-width:$breakpoint-md){
    padding-top: 0;
  }
  .message-header{
    width:100%;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    @media(max-width:650px){
      flex-direction:column;
      justify-content:space-between;
      align-items:flex-start;
      height:80px;
      .import-messages, .message-blueprint{
        margin:0 !important;
      }
    }
    .message-title{
      color: #252525;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      font-weight: 700;
      margin-right:30px;
    }
    .import-messages{
      color: #252525;
      font-size:14px;
      font-weight:500;
      font-family:'Montserrat', sans-serif;
      text-decoration:underline;
      margin:0 5px;
      cursor:pointer;
      &:hover{
        color:#939393;
      }
    }
    .message-blueprint{
      color: #252525;
      font-size:14px;
      font-weight:500;
      font-family:'Montserrat', sans-serif;
      text-decoration:underline;
      margin:0 5px;
      cursor:pointer;
      &:hover{
        color:#939393;
      }
    }
  }
}
.message-module{
  margin-top:50px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.15);
  width:100%;
  height:auto;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  padding:20px;
  @media(max-width:500px){
    .global-btn{
      align-self:center;
    }
  }
  .message-receiver{
    color: #252525;
    font-family: 'Montserrat', sans-serif;
    font-size:12px;
    font-weight:700;
  }
  .textarea-wrapper{
    width:100%;
    position:relative;
    margin-bottom:10px;
    .characters-remaining{
      position:absolute;
      bottom:10px;
      right:0;
      color: #969696;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      text-align:left;
      font-style: italic;
      font-weight: 400;
    }
    .message-content{
      border:none;
      border-bottom:1px solid #DBDBDB;;
      outline:none;
      resize:none;
      width:100%;
      margin-top:20px;
      color: #000;
      text-align: left;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 400;
      &::placeholder{
        color: #969696;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        text-align:left;
        font-style: italic;
        font-weight: 400;
      }
    }
  }
  .send-message{
    border:none;
    outline:none;
    border-radius: 100px;
    background: #98D9C2;
    padding:10px 15px;
    margin-top:30px;
    color: #252525;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
  }
}
.assign-user-frame {
  min-width: 30px;
  //   width: 100%;
  min-height: 40px;
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.message-input-box {
  // max-width: 600px;
  width: 100%;
  height: 500px;
  margin: auto;
  resize: none;
  padding: 15px;
  border-radius: 10px;
  color: #333033;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid #a1a1a1;
}

.user-list-wrapper {
  margin-top: 20px;
  width: 100%;
  height: 423px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #a1a1a1;
}

.send-message-btn {
  padding: 10px 50px;
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 50px;
  border: none;
  margin-top: 30px;
}
