.help-wrapper{
	width:100%;
	height:100%;
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	justify-content:flex-start;
	.help-header{
		color: #252525;
		font-family: 'Montserrat', sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin: 0 0 56px 0;
	}
	.help-container{
		width:100%;
		height:100%;
		display:flex;
		align-items:flex-start;
		justify-content:space-between;
		.questions{
			width:40%;
			@media(max-width:$breakpoint-md){
				width:100%;
			}
			.accordion-wrapper{
				border-bottom:0.5px solid #DBDBDB;
				padding-bottom:30px;
				margin-bottom:30px;
				cursor:pointer;
				.accordion-header-wrapper{
					display:flex;
					justify-content:space-between;
					align-items:center;
					width:100%;
					.accordion-header{
						color: #252525;
						font-family: 'Montserrat', sans-serif;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						-webkit-user-select: none; /* Chrome, Safari, Opera */
						-moz-user-select: none; /* Firefox */
						-ms-user-select: none; /* Internet Explorer/Edge */
						user-select: none;
					}
				}
				&.open .accordion-triangle {
					transform: rotate(180deg); // Obrót trójkąta
				}
				.question-answer{
					color: #252525;
					font-family: 'Montserrat', sans-serif;
					max-height:0;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					opacity: 0;
					overflow: hidden;
					transition: max-height 0.5s ease, opacity 0.5s ease, padding-top 0.5s ease;
					-webkit-user-select: none; /* Chrome, Safari, Opera */
					-moz-user-select: none; /* Firefox */
					-ms-user-select: none; /* Internet Explorer/Edge */
					user-select: none;
					padding-top:0;
				}
				&.open .question-answer {
					opacity: 1;
					padding-top:32px;
					max-height:1000px;
				}
				&:hover{
					.accordion-header{
						color:#939393;
					}
				}
			}
		}
		.help-image{
			width:55%;
			height:auto;
			@media(max-width:$breakpoint-md){
				display:none;
			}
		}
	}

}
