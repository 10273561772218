.shop-page-wrapper {
  // max-width: 1000px;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pricing-options{
  width:100%;
  height: 100%;
  display:flex;
  justify-content: center;
  align-content: center;
  gap:16px;
}
.list-wrapper {
  position: relative;
  width: 220px;
  height:100%;
  max-height:640px;
  border-radius: 8px;
  border: 1px solid #acacac;
  &.price-list{
    width:203px;
    margin-right:24px;
    box-sizing:border-box;
  }
  &.promote-list {
    border: 1px solid #98D9C2;
    border-radius: 0px 0px 8px 8px;
    max-height:calc(675px - 19px);
    height:calc(675px - 19px);
    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 20px;
    }
  }
  .promote-title-wrapper {
    position: absolute;
    top: -20px;
    height: 19px;
    left: -1px;
    right: -1px;
    border-radius: 8px 8px 0px 0px;
    background: #98D9C2;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: #fff;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }
  .list-title {
    width: 100%;
    height: 119px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    position: relative;
    &.here{
      &::after {
        position: absolute;
        content: '';
        left: 10px;
        right: 10px;
        height: 1px;
        background: #acacac;
        bottom: 0;
      }
    }
    .name {
      color: #333033;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    .price {
      color: #333033;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Montserrat', sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .period {
      color: #333033;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 200% */
    }
  }
  .list-title-pricing {
    // justify-content: space-between;
  }
  .pricing {
    font-size: 40px;
    color: rgb(137, 137, 137);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
  }
  .pricing-list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    div {
      padding: 7px 0;
      height: 64px;
      height: 64px;
      width: 100%;
      li {
        background-color: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 20px;
        color: black;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }
  .offer-list {
    &.here {
      div {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 10px;
          right: 10px;
          background: #acacac;
          height: 1px;
        }
      }
    }
    div {
      height: 60px;
      width: 100%;
      position: relative;
      li {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .key {
          display: none;
        }
        .value,
        .key {
          color: #000;
          text-align: center;
          leading-trim: both;
          text-edge: cap;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      @media screen and (max-width: $breakpoint-sm) {
        li {
          p {
            width: 50%;
          }
          .key {
            display: block;
            text-align: end;
            padding-right: 10px;
          }
          .value {
            text-align: start;
            padding-left: 10px;
          }
        }
      }
    }
  }
  .offer-options{
    .value,
    .key {
      text-align: right !important;
      width:100% !important;
    }
  }
  .button-wrapper {
    height: 102px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    .buy-button {
      max-width: 146px;
      width: 100%;
      height: 47px;
      flex-shrink: 0;
      border-radius: 50px;
      border: 1px solid #98D9C2;
      background: #fff;
      color: #98D9C2;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      transition: 300ms;
      cursor: pointer;
    }
    .buy-button-no-promote:hover {
      background: #98D9C2;
      border: 1px solid #98D9C2;
      color: #fff;
    }

    .buy-button-promote {
      background: #98D9C2;
      color: #fff;
    }

    .buy-button-promote:hover {
      background: #98D9C2;
      border: 1px solid #98D9C2;
      color: #fff;
    }
  }
}

.price-list {
  border: none;
}
.device-quantity-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .info-box {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    background-color: #fff;
  }
}

.main-title-shop {
  color: #333033;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 45px;
  margin-bottom: 10px;
}

.under-title-shop {
  color: #333033;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 15px;
}
.page-wrapper{
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .options-header{
    font-family:'Montserrat', sans-serif;
    font-size:18px;
    color:#252525;
    font-weight:600;
    margin-right:40px;

  }
  .detail-header-wrapper{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom:5px;
  }
  .time-left{
    font-family:'Montserrat', sans-serif;
    font-size:14px;
    font-weight:400;
    color:#252525;
    margin-bottom:25px;
  }
  .option-details-container{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom:75px;
    .details-header{
      font-family:'Montserrat', sans-serif;
      font-size:14px;
      font-weight:600;
      color:#252525;
    }
    .option-detail{
      font-family:'Montserrat', sans-serif;
      font-size:14px;
      font-weight:400;
      color:#252525;
    }
  }
  .option-payments-container{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom:75px;
    .method-container{
      width:600px;
      height:160px;
      border:1px solid #252525;
      border-radius:8px;
      display:flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      padding:20px;
      .method-header{
        width:100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        .method-name{
          font-family: 'Montserrat', sans-serif;
          font-size:14px;
          font-weight:400;
          color:#252525;
        }
      }
      .method-number{
        font-family: 'Montserrat', sans-serif;
        font-size:14px;
        font-weight:400;
        color:#252525;
        width:100%;
        text-align:left;
        letter-spacing:10px;
      }
      .method-footer{
        width:100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        .method-expire{
          font-family: 'Montserrat', sans-serif;
          font-size:14px;
          font-weight:400;
          color:#252525;
        }
      }
    }
  }
  .transaction-table {
    width: 100%;
    font-family:'Montserrat', sans-serif;
    font-size:14px;
    font-weight:400;
    .no-invoices{
      font-size:14px;
      font-family: 'Montserrat', sans-serif;
      font-weight:400;
      color:#252525;
      margin-top:30px !important;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      tr{
        border-bottom:0.5px solid #DBDBDB;
        padding:5px 0;
      }
      th {
        padding: 20px 10px;
        text-align: left;
      }
      .index {
        width: 5%;
      }
      .date {
        width: 10%;
      }
      .invoice {
        width: 15%;
      }
      .amount {
        width: 10%;
      }
      .status {
        width: 60%;
        color: #98D9C2;
      }
      .download{
        position:relative;
        .download-button{
          width:150px;
          height:50px;
          font-family: 'Montserrat', sans-serif;
          font-size:14px;
          font-weight:500;
          cursor:pointer;
          border:none;
          border-radius:10px;
          background-color:#fff;
          -webkit-box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
          -moz-box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
          box-shadow: 0px 0px 18px -9px rgba(66, 68, 90, 1);
          position: absolute;
          top:-20px;
          left:-140px;
        }
      }
    }
  }
  .payment-container{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .billing-type{
      display:flex;
      max-width:450px;
      width:100%;
      justify-content: space-between;
      align-items: center;
      @media(max-width:500px){
        padding:0 20px;
      }
      @media(max-width:420px){
        justify-content: center;
        padding:0;
      }
      @media(max-width:375px){
        justify-content: space-between;
      }
      .switch-container{
        width:50px;
        height:20px;
        border:1px solid #252525;
        border-radius:10px;
        display:flex;
        justify-content: flex-start;
        align-items: center;
        cursor:pointer;
        padding:0 2px;
        @media(max-width:420px){
          margin:0 5px;
        }
        .switch-pill{
          width:25px;
          height:14px;
          //border:1px solid #252525;
          border-radius:10px;
          background-color:#98D9C2;
        }
        &.active{
          justify-content: flex-end;
        }
      }
    }
    .payment-text{
      font-family: 'Montserrat', sans-serif;
      font-size:14px;
      font-weight:400;
      color:#252525;
      &.type{
        font-weight:600;
        @media(max-width:375px){
          &:first-of-type{
            max-width:50px;
          }
          max-width:90px;
        }
      }
      &.payment-header{
        font-weight:600;
        margin-bottom:30px;
      }
      &.checkbox-text{
        width:225px;
        margin-bottom:30px;
      }
      &.active{
        color:#98D9C2;
      }
    }
    .billing-info-container{
      width:100%;
      display:flex;
      flex-wrap:wrap;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction:row;
      margin-top:50px;
      gap:10px;
      @media(max-width:$breakpoint-md){
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding:20px 0;
      }
      .info-container{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .invoice-data{
          width:330px;
          padding:25px 20px;
          border:1px solid #252525;
          border-radius:8px;
          display:flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          span:last-child{
            margin:40px 0 0 0 !important;
            &.single{
              margin:0 !important;
            }
          }
        }
      }
    }
    .payment-type-container{
      width:330px;
      height:57px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding:20px 15px;
      border:1px solid #252525;
      border-radius:10px;
      margin-bottom:15px;
      .payment-type{
        margin-left:15px;
      }
      input[type="radio"] {
        display: none;
      }
      &.error{
        border:1px solid red;
      }

      /* Stylizacja niestandardowego przycisku radio */
      input[type="radio"] + label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        display: inline-block;
        font-size: 18px;
        line-height: 24px;
        user-select: none;
      }

      input[type="radio"] + label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-85%);
        width: 15px;
        height: 15px;
        border: 1px solid #252525;
        border-radius: 50%;
        background-color: transparent;
      }

      /* Stylizacja zaznaczonego przycisku radio */
      input[type="radio"]:checked + label::before {
        background-color: #fff;
        border: 1px solid #252525;
      }

      input[type="radio"]:checked + label::after {
        content: '';
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-95%);
        width: 13px;
        height: 13px;
        background-color: #252525;
        border-radius: 50%;
      }
    }
    .payment-sumup-container{
      width:300px;
      min-height:607px;
      border:1px solid #252525;
      border-radius:10px;
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .payment-charge{
        width:100%;
        padding:30px 20px;
        border-bottom:1px solid #252525;
        gap:5px;
        &:last-child{
          border:none;
        }
        .inputs-container{
          display:flex;
          justify-content: space-between;
          align-items: flex-start;
          gap:10px;
          width:100%;
          &:last-child{
            span{
              margin-bottom:20px !important;
            }
          }
        }
        .payment-details{
          margin:30px 0;
          display:flex;
          gap:15px;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          .payment-detail{
            font-size:14px;
          }
        }
        .payment-sum-container{
          width:100%;
          display:flex;
          justify-content: flex-end;
          align-items: center;
          .payment-sum{
            font-family: 'Montserrat', sans-serif;
            font-size:36px;
            font-weight:600;
          }
        }
        input[type='checkbox']{
          display:none;
          position:relative;
        }
        input[type="checkbox"] + label{
          position: relative;
          padding-left: 20px;
          padding-top:20px;
          cursor: pointer;
          display: inline-block;
          font-size: 18px;
          line-height: 24px;
          user-select: none;
          border:1px solid #252525;
          border-radius:6px;
        }
        input[type="checkbox"]:checked + label{

        }
      }
    }
  }
}
.error-text{
  font-size:14px;
  color:red;
  font-family: 'Montserrat', sans-serif;
  font-weight:400;
}
