.page-register {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .left {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position:relative;
    justify-content: flex-start;
    align-items: center;
    .back-to-main-page-icon-black{
      display:none;
      @media(max-width: $breakpoint-sm){
        display:block;
      }
    }
    .logo-container{
      width:360px;
      height:30%;
      padding-top:40px;
      display:flex;
      justify-content: flex-start;
      align-items: flex-start;
      justify-self: flex-start;
      @media(max-width: 360px){
        width:100%;
      }
    }
    .discount-box {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #54d76a;
      background: #ebfbee;
      height: 72px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
      img {
        margin: 0 25px;
      }
      p {
        color: #4fb05f;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .right {
    width: 40%;
    height: 100%;
    background-image: url('../../assets/images/login/nowe_konto.jpg');
    background-color: #fec853;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .inputs-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    .input-container {
      width: 100%;
      margin-top:20px;
    }
  }
  .main-page-title {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #252525;
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
  }
  .title-description {
    display: flex;
    width: 368px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #333033;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .login-elements-wrapper-width-captcha{
    margin-top: 53px;
  }
  .privacy-policy-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:100%;
    margin-top: 22px;
    .privacy-policy {
      flex-shrink: 0;
      color: #555;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 100% */
      margin-left: 20px;
      max-width: 315px;
      a {
        color: #555;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        text-decoration-line: underline;
      }
    }
  }
  .login-elements-wrapper-with-captcha {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .save-login {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      p {
        color: #333033;
        leading-trim: both;
        text-edge: cap;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  a {
    color: #333033;
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  .login-vertical-elements{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px
  }
  .login-button {
    border-radius: 100px;
    background: #98D9C2;
    color: #252525;
    padding:15px 30px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    cursor:pointer;
    outline:none;
    margin:20px 0;
  }
  .has-account {
    color: #555;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:50px;
    a {
      color: #555;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}

.register-error {
  color: red;
  margin-top: 5px;
  font-size:12px;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: $breakpoint-sm) {
  .page-register {
    .right {
      display: none;
    }
    .left {
      width: 100%;
      padding: 0 10px;
      .main-page-title {
        width: 100%;
        margin-bottom: 50px;
      }
      .title-description {
        width: 100%;
      }
      .privacy-policy-wrapper {
        .privacy-policy {
          width: calc(100% - 50px);
        }
      }
    }
  }
}
