.profile{
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .profile-header{
    font-family:'Montserrat', sans-serif;
    font-weight:600;
    font-size:18px;
    color:#252525;
  }
  .inputs-container{
    width:750px;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap:wrap;
    @media(max-width:1050px){
      width:100%;
      gap:10px;
    }
    .profile-password{
      display:flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .info{
    font-family:'Montserrat', sans-serif;
    font-size:14px;
    font-weight:400;
    color:#252525;
    margin-top:20px;
    margin-bottom:40px;
  }
  .sub-component-container{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .error{
      font-family:'Montserrat', sans-serif;
      font-size:14px;
      color:red;
      font-weight:400;
      margin-bottom:17px;
    }
    .close-container{
      width:100%;
      display:flex;
      justify-content: flex-end;
      svg{
        cursor: pointer;
      }
    }
    .container{
      display:flex;
      max-width:470px;
      width:100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .sub-component-header{
        font-family:'Rubik', sans-serif;
        font-weight:600;
        font-size:36px;
        color:#333033;
      }
      .sub-component-text{
        font-family:'Montserrat', sans-serif;
        font-size:14px;
        font-weight:400;
        color:#333033;
        margin-top:15px;
        margin-bottom:30px;
      }
    }
  }
}
.popup{
  width:270px;
  height:140px;
  border-radius:8px;
  box-shadow:0 0 15px 0 #000;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  span {
    margin-bottom:30px;
    font-family:'Montserrat', sans-serif;
    font-size:18px;
    font-weight:400;
    color:#252525;
  }
}
