.page-reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10px;
    .title {
      color: #333033;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Montserrat', sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 10px;
    }
    .under-title {
      color: #333033;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-bottom: 30px;
    }
    button {
      border-radius: 50px;
      background: #ea0a8e;
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 19px 25px;
      border: none;
      margin-top: 30px;
    }
  }
}
