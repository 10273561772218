.import-wrapper {
	width: 100%;
	box-sizing: border-box;
	height: 100%;
	@media(max-width:$breakpoint-md){
		padding-top: 0;
	}
	.import-header {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		@media(max-width:450px){
			flex-direction:column;
			justify-content:space-between;
			align-items:flex-start;
			height:80px;
		}
		.add-file {
			border: none;
			outline: none;
			border-radius: 100px;
			background: #98D9C2;
			padding: 10px 15px;
			color: #252525;
			text-align: center;
			font-family: 'Montserrat', sans-serif;
			font-size: 14px;
			font-weight: 600;
			cursor:pointer;
		}

		.import-messages {
			color: #252525;
			font-size: 14px;
			font-weight: 500;
			font-family: 'Montserrat', sans-serif;
			text-decoration: underline;
			cursor: pointer;
			margin-left:30px;
			&:hover{
				color:#939393;
			}
			@media(max-width:450px){
				margin:0;
			}
		}
	}

	.comissioned-messages, .accepted-messages {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-content: flex-start;
		padding:30px 0;
		@media(max-width:$breakpoint-xs){
			padding:0;
		}
		.import-subheader{
			color: #252525;
			font-family: 'Montserrat', sans-serif;
			font-size: 20px;
			font-weight: 700;
			margin:10px 0 30px 0;
		}
		.header-element {
			color: #555;
			font-family: 'Montserrat', sans-serif;
			font-size: 12px;
			font-weight: 700;
		}
		.comissioned-header{
			@media(max-width:$breakpoint-xs){
				display:none;
			}
		}
		.messages-list{
			.no-data-to-show-container{
				width:100%;
				height:100px;
				display:flex;
				justify-content:center;
				align-items:center;
				.no-data-to-show{
					color: #DBDBDB;
					height:20px;
					font-family: 'Montserrat', sans-serif;
					font-size: 14px;
					font-weight: 500;
					text-align:center;
				}
			}
			@media(max-width:$breakpoint-xs){
				padding:30px 0;
			}
			.list-element{
				padding:30px 0;
				border-bottom:1px solid #DBDBDB;
				@media(max-width:$breakpoint-xs){
					border:none;
					padding:0;
				}
				.grid-item{
					display:flex;
					flex-direction:column;
					justify-content:center;
					align-content:flex-start;
				}
				.pc-single-file{
					@media(max-width:$breakpoint-xs){
						display:none;
					}
					.show-queue{
						color: #252525;
						font-family:'Karla', sans-serif;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-decoration-line: underline;
					}
				}
				.mobile-single-file{
					display:flex;
					flex-direction:column;
					justify-content:flex-start;
					align-content: space-between;
					width:100%;
					border-radius:10px;
					box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.15);
					@media(min-width:$breakpoint-xs){
						display:none;
					}
				}
				.file-title{
					padding:30px 20px;
					border-bottom:1px solid #DBDBDB;
				}
				.file-number{
					color: #252525;
					font-family: 'Montserrat', sans-serif;
					font-size: 14px;
					font-weight: 700;
				}
				.file-name{
					color: #252525;
					font-family: 'Montserrat', sans-serif;
					font-size: 14px;
					font-weight: 700;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.accept{
					border: none;
					outline: none;
					border-radius: 100px;
					background: #98D9C2;
					padding: 10px 15px;
					color: #252525;
					text-align: center;
					font-family: 'Montserrat', sans-serif;
					font-size: 14px;
					font-weight: 600;
					cursor:pointer;
				}
				.statistics{
					display:flex;
					flex-direction: column;
					justify-content:flex-start;
					align-items: flex-start;
					@media(max-width:$breakpoint-xs){
						padding:30px 20px;
						border-bottom:1px solid #DBDBDB;
						justify-content:space-between;
						height:100px;
					}
					.amount{
						color: #333033;;
						font-family: 'Montserrat', sans-serif;
						font-size: 14px;
						font-weight: 500;
					}
					.sent-messages, .error-messages{
						font-family: 'Montserrat', sans-serif;
						font-size: 14px;
						margin-bottom:20px;
						font-weight: 500;
						display:flex;
						justify-content:space-between;
						align-items:center;
						width:160px;
						@media(max-width:$breakpoint-xs){
							margin:0;
						}
					}
					.sent-messages{
						color: #23DA36;
					}
					.error-messages{
						color: #D82929;

					}
				}
				.file-queue{
					display:flex;
					flex-direction:column;
					align-items:flex-start;
					justify-content:center;
					@keyframes rotation {
						0% {
							transform: rotate(0deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}
					.file-status{
						color: #252525;
						font-family: 'Montserrat', sans-serif;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
					@media(max-width:$breakpoint-xs){
						padding:30px 20px;
						border-bottom:1px solid #DBDBDB;
					}
					.file-amount{
						color: #252525;
						font-family: 'Montserrat', sans-serif;
						font-size: 14px;
						font-weight: 700;
						margin-bottom:20px;
					}
				}
			}
		}
	}
}
