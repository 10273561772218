.contact-page-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    .header-btn-container{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-grow:1;
    }
  }
  .table-box-wrapper {
    margin-top: 80px;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    .empty-contacts{
      width:100%;
      height:300px;
      display:flex;
      justify-content:center;
      align-items:center;
      .empty-contacts-title{
        color: #DBDBDB;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }
    }
    .contacts-header{
      color: #555;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .contacts-list {
      width: 100%;
      margin-top:20px;
      .contacts-item{
        padding:20px 0;
        border-bottom:1px solid #DBDBDB;
        .user-picture {
          width: 60px;
          height: 60px;
          border-radius: 60px;
          background: #ffda10;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-align: center;
          font-size: 22px;
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .user-info{
          color: #252525;
          font-family: 'Karla', sans-serif;
          display:flex;
          justify-content:flex-start;
          align-items:center;
          height:100%;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .sended-messages-wrapper {
          width: 100%;
          display: flex;
          height:100%;
          align-items:center;
        }
      }
    }
  }
  .pagination {
    color: #939393;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
    button {
      border: none;
      background-color: transparent;
      color: #939393;
      leading-trim: both;
      text-edge: cap;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
.contact-popup{
  cursor:pointer;
  &:hover{
    background:#E9E9E9;
  }
}
.contact-component-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

@media screen and (max-width: $breakpoint-md) {
  .contact-page-wrapper {
    padding: 0;
    .header {
      justify-content: center;
      flex-direction: column;
      button {
        margin-bottom: 40px;
      }
      p {
      }
    }
    .table-box-wrapper {
      margin-top: 80px;
      width: 100%;
      max-height: 600px;
      overflow-y: auto;
      table {
        .main-row {
        }
        tr {
          .name {
          }
          th {
          }
          td {
            padding-right: 20px;
          }
        }
      }
    }
  }
}

.send-msg-container{
  width:440px;
  height:385px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  padding:30px 20px;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  @media(max-width:$breakpoint-md){
    width:100%;
    height:100%;
  }
  .msg-error{
    color: red;
    margin-top:5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .send-msg-header{
    color: #252525;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom:30px;
  }
  .send-msg-recipient{
    border-radius: 100px;
    background: #EDEDED;
    padding:7px 8px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:15px;
    .user-avatar{
      color: #FFF;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      background-color:#EF8533;
      border-radius:100px;
      display:flex;
      justify-content:center;
      align-items:center;
      width:23px;
      height:23px;
      margin-right:13px;
    }
    .user-name{
      color: #252525;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
    }
  }
  .msg-content-container{
    width:100%;
    height:155px;
    position: relative;
    @media(max-width:$breakpoint-md){
      height:100%;
    }
    .msg-content{
      resize:none;
      width:100%;
      height:100%;
      padding:15px 0;
      outline:none;
      border-right:none;
      border-left:none;
      border-top: 0.5px solid #DBDBDB;
      border-bottom: 0.5px solid #DBDBDB;
      color: #000;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .character-count{
      position:absolute;
      bottom:5px;
      right:5px;
      color: #939393;
      text-align: center;;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
  }
  .buttons-container{
    width:100%;
    display:flex;
    justify-content:flex-end;
    align-items:center;
    margin-top:15px;
    .button-cancel{
      color: #252525;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      cursor:pointer;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      margin-right:30px;
      &:hover{
        color: #939393;
      }
    }
  }
}
